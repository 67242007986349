import { useEffect } from "react";

export const useInfiniteScroll = (selector, callback) => {

  const handleScroll = () => {
    const container = document.querySelector(selector)
    if (!container) {
      return
    }

    const scrollY = container.scrollHeight - container.scrollTop;
    const height = container.offsetHeight;
    const offset = height - scrollY;

    if (offset === 0 || offset === 1) {
      callback && callback()
    }
  }

  useEffect(() => window.addEventListener("scroll", handleScroll), [])

  return () => window.removeEventListener("scroll", handleScroll)
}
