import React, { useRef } from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { usePreaches, usePreachesPage } from "../services/firestore";
import { useInfiniteScroll } from '../services/utils'

const PreachCard = ({preach}) => {
  const formatDate = (dateStr) => {
    const date = dateStr && new Date(dateStr)
    return date ? date.toLocaleDateString("fr-FR") : ""
  }
  return (
    <div className="preach lg:pr-[50px] lg:mb-[50px] mb-14">
      <div className="image px-4 lg:px-0">
        <a href={preach.youtubeURL} target="blank">
          <img src={preach.image.src} className="w-full hover:opacity-95 rounded-lg" alt={preach.title} />
        </a>
      </div>
      <div className="header flex flex-col px-4 pt-4 lg:flex-row lg:px-0 lg:pt-4">
        <div className="title flex-1">
          <a className="text-2xl font-semibold hover:underline" href={preach.youtubeURL} target="blank">
            {preach.title}
          </a>
          <div className="preacher text-md">{preach.preacher}</div>
        </div>
        <div className="date text-md lg:ml-4">{formatDate(preach.date)}</div>
      </div>
    </div>
  )
}

const PredicationsPage = ({data}) => {
  const page = usePreachesPage(data.allPreachesPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
  };

  const [preaches, loadMore] = usePreaches({
    items: [],
    moreAvailable: false
  })
  const preachesRef = useRef()
  preachesRef.current = preaches
  const loadMoreRef = useRef()
  loadMoreRef.current = loadMore

  const removeInfiniteScroll = useInfiniteScroll("#preaches", () => {
    if (preachesRef.current.moreAvailable) {
      loadMoreRef.current()
    } else {
      removeInfiniteScroll()
    }
  })

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Prédications"}>
      <div id="preaches" class="container mx-auto grid grid-cols-1 lg:grid-cols-3 lg:py-[96px] mt-12 lg:mt-0">
        {preaches.items.map((preach) => {
          return <PreachCard preach={preach}></PreachCard>
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPreachesPages {
      nodes {
        heroBannerType
        heroBannerImage {
          image
          src
        }
      }
    }
  }
`;

export default PredicationsPage;

